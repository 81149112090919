<template>
  <section class="order-area banner-main-area flight-area card">
    <div class="card-header">
      <div class="container">
        <h1><img :src="`${speedSizeDomain}/assets/img/app/order-icon9.png`" alt="#" title="" />טיסות</h1>
      </div>
    </div>
    <div class="card-body pt-3">
      <div class="container">
        <single-flight-item-app
          :flight="activeFlight"
          :showExtraInfo="true"
          direction="departure"
        />
        <div class="box-listing">
          <ul>
            <li><img :src="`${speedSizeDomain}/assets/img/app/order-icon10.png`" alt="#" /><strong>{{ $t("booking.airline-name") }} : </strong
              >{{ outward.airlineName }}</li>
            <li><img :src="`${speedSizeDomain}/assets/img/app/order-icon10.png`" alt="#" /><strong>{{ $t("booking.airline-code") }} : </strong
              >{{ outward.airlineCode }}</li>
            <li><img :src="`${speedSizeDomain}/assets/img/app/order-icon10.png`" alt="#" /><strong>{{ $t("booking.airline-number") }} : </strong
              >{{ outward.airlineNumber }}</li>
            <li><img :src="`${speedSizeDomain}/assets/img/app/order-icon11.png`" alt="#" /><strong>{{ outward.departureAddress }}</strong></li>
            <li><img :src="`${speedSizeDomain}/assets/img/app/order-icon3.png`" alt="#" /><strong>{{ $t("booking.departure") }} : </strong>{{ outward.depature.dateTime || "" }}</li>
            <li class='mergeLine' v-if="outward.depature.notFinal">
              <span>Time not final</span>
            </li>
            <li><img :src="`${speedSizeDomain}/assets/img/app/order-icon3.png`" alt="#" /><strong>{{ `${$t("booking.arrivalTo")} ${outward.arrivalAddress} : ` }}</strong>{{ outward.arrival.dateTime || ""}}</li>
            <li class='mergeLine' v-if="outward.arrival.notFinal">
              <span>Time not final</span>
            </li>
            <li><img :src="`${speedSizeDomain}/assets/img/app/icon6.png`" alt="#" /><strong>{{ $t("booking.including") }} : </strong>{{ outward.including }}kg</li>
            <li class="flight-confirmed"><img :src="`${speedSizeDomain}/assets/img/app/order-icon10.png`" alt="#" /><strong>{{ $t("booking.flight-confirmed") }} : </strong
              >{{ outward.confirmed }}</li>
          </ul>
        </div>
        <div class="flight-box">
          <img :src="`${speedSizeDomain}/assets/img/app/flight-icon2.png`" class="flightIcon" alt="">
        </div>
        <single-flight-item-app
          :flight="activeFlight"
          :showExtraInfo="true"
          direction="arrive"
        />
        <div class="box-listing">
          <ul>
            <li><img :src="`${speedSizeDomain}/assets/img/app/order-icon10.png`" alt="#" /><strong>{{ $t("booking.airline-name") }} : </strong
              >{{ inward.airlineName }}</li>
            <li><img :src="`${speedSizeDomain}/assets/img/app/order-icon10.png`" alt="#" /><strong>{{ $t("booking.airline-code") }} : </strong
              >{{ inward.airlineCode }}</li>
            <li><img :src="`${speedSizeDomain}/assets/img/app/order-icon10.png`" alt="#" /><strong>{{ $t("booking.airline-number") }} : </strong>{{ inward.airlineNumber }}</li>
            <li><img :src="`${speedSizeDomain}/assets/img/app/order-icon11.png`" alt="#" /><strong>{{ inward.departureAddress }}</strong></li>
            <li><img :src="`${speedSizeDomain}/assets/img/app/order-icon3.png`" alt="#" /><strong>{{ $t("booking.departure") }} : </strong>{{ (inward.depature.dateTime || "") }}</li>
            <li class="mergeLine" v-if="inward.depature.notFinal">
              <span>Time not final</span>
            </li>
            <li><img :src="`${speedSizeDomain}/assets/img/app/order-icon3.png`" alt="#" /><strong>{{ `${$t("booking.arrivalTo")} ${inward.arrivalAddress} : ` }}</strong>{{ inward.arrival.dateTime || ""}}</li>
            <li class="mergeLine" v-if="inward.arrival.notFinal">
              <span>Time not final</span>
            </li>
            <li><img :src="`${speedSizeDomain}/assets/img/app/icon6.png`" alt="#" /><strong>{{ $t("booking.including") }} : </strong>{{ inward.including }}kg</li>
            <li class="flight-confirmed"><img :src="`${speedSizeDomain}/assets/img/app/order-icon10.png`" alt="#" /><strong>{{ $t("booking.flight-confirmed") }} : </strong>{{ inward.confirmed }}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  components: {
    SingleFlightItemApp: () => import('@/components/productPage/atoms/SingleFlightItemApp'),
  },
  props: {
    flights: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      outward: this.flights.outward,
      inward: this.flights.inward,
      activeFlight: null,
    };
  },
  created() {
    const product = JSON.parse(window.sessionStorage.getItem('production'));
    const flightId = `${this.outward.airlineID}${this.inward.airlineID}`;
    this.activeFlight = product.flights.find((item) => `${item.fl_id1}${item.fl_id2}` === flightId);
  },
};
</script>

<style scoped>
.after_booking_area .hotel_details_area {
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
}

.hotel_details_area .hotel_details_heading {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(39, 136, 210, 1) 0%,
    rgba(1, 98, 172, 1) 100%
  );
  padding: 15px 20px;
}
.hotel_details_heading h3 {
  margin: 0 0;
  padding: 0 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
}
.hotel_details_body {
  padding: 35px 20px;
  border: 1px solid #949494;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 25px;
}
.hotel_details_body .flight_box_div {
  padding: 0px 15px;
}
.hotel_details_body .flight_box_div ul {
  padding: 0 0;
  margin: 0 0;
  list-style: none;
}
.hotel_details_body .flight_box_div ul li {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}
.hotel_details_body .flight_box_div ul li .icon {
  margin-left: 10px;
}

.hotel_details_body .flight_box_div.flight {
  margin: auto;
  text-align: center;
}

.hotel_details_body .flight_box_div.flight img {
  width: 175px;
}

.mergeLine{
  margin-top: -10px;
}

.flight-confirmed > strong{
  width: 53%;
}

@media (max-width: 1200px) {
  .hotel_details_body .flight_box_div.flight img {
    width: 70px;
  }
  .hotel_details_body .flight_box_div {
    padding: 0px 5px;
  }
}

@media (max-width: 479px) {
  .hotel_details_area .hotel_details_heading h3 {
    font-size: 20px;
  }
  .hotel_details_body .d-flex {
    display: block !important;
  }
  .hotel_details_body {
    padding: 35px 0px;
  }
  .hotel_details_body .flight_box_div.flight {
    margin-bottom: 15px;
  }
  .hotel_details_body .flight_box_div.flight img {
    width: 125px;
  }
}
</style>

<style lang="less" scoped>
  .bonauf-desktop {
    .apparea {
      .order-area {
        .container {
          max-width: 780px;
          margin: auto;

          h1 {
            font-size: 30px;
            padding: 20px 0px;
          }
        }
        .card-header:before {
          width: 60px;
          height: 140px;
        }
        .card-body:before {
          width: 120px !important;
          height: 150px !important;
        }
      }
    }
  }
</style>
